export const BSC = 56
export const KAVA = 2222
export const MANTLE_TESTNET = 5001
export const addresses = {
  [KAVA]: {
    //FMX: "0x599b05875866ceb7378452d9f432d5377825f44b",
    ETH: "0xe3f5a90f9cb311505cd691a46596599aa1a0ad7d",
    BTC: "0x818ec0a7fe18ff94269904fced6ae3dae6d6dc0b",
    KAVA: "0xc86c7c0efbd6a49b35e8714c5f59d99de09a225b",
    // MATIC: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    RewardReader: "0x5b94269901680541149c8fb50f5f4427b75ff201",
    FLP: "0xdc7971347d7a92fde6f7f46aa8c49898db66a934",
    FlpManager: "0x9cc069ab14fdd53d434a1721ab1060b7e2f84251",
  },
  [MANTLE_TESTNET]: {
    //FMX: "0x6cADe7E303D3Ad722cD5d3f2569A8d139b727F91",
    ETH: "0x83b495522dF1f4029CaE623081bb664918462567",
    BTC: "0x0eA56107fb256FC085214002D6fFaB86C57F9d4E",
    MNT: "0x8734110e5e1dcF439c7F549db740E546fea82d66",
    // MATIC: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    RewardReader: "0xBCDf9917D21b7b33479644a208d71C752c23Ea38",
    FLP: "0x8e4e2066B5AE4880255933B307BBf3ecFE0d42B3",
    FlpManager: "0x6d9A685475B2d77a1A64Fc041d27c47ED22aEBd1",
  },
};

export function getAddress(chainId, key) {
    if (!(chainId) in addresses) {
        throw new Error(`Unknown chain ${chainId}`)
    }
    if (!(key in addresses[chainId])) {
        throw new Error(`Unknown address key ${key}`)
    }
    return addresses[chainId][key]
}
